<template>
    <div :style="{minHeight: '105vh'}" class="white">
        <v-container>
            <Overlay :overlay="overlay"/>

            <v-row align="center" justify="center">
                <v-col cols="12" lg="6">
                    <v-card class="transparent" flat>

                        <v-card-text v-if="success" class="text-center">
                            <div class="success pa-10 rounded-lg">

                                <v-avatar class="bg-grad-white" size="100">
                                    <v-icon color="white" large>mdi-check</v-icon>
                                </v-avatar>

                                <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-8 white--text">
                                    Félicitations !
                                </h1>

                                <p class="mb-0 white--text">
                                    Le flexi a été envoyé à votre numéro avec succès
                                </p>
                            </div>
                        </v-card-text>

                        <v-card-text v-else class="text-center">

                            <img :src="require('@/assets/mobile.svg')" alt="" width="220"/>

                            <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-4 secondary--text">
                                Bienvenu cher client !
                            </h1>

                            <v-alert v-if="success"
                                     border="bottom"
                                     class="fs-14"
                                     dense
                                     prominent
                                     type="success">
                                Aucun compte Gifty ne correspond à ce numéro !
                            </v-alert>

                            <p>
                                Veuillez entrer votre numéro de téléphone.
                            </p>

                            <form @submit.prevent="send">

                                <v-text-field v-model="consumer_phone"
                                              v-number
                                              :error-messages="errors.consumer_phone || errors.code"
                                              @input="errors = {}"
                                              clearable
                                              counter="10"
                                              outlined
                                              persistent-hint
                                              placeholder="Votre numéro de téléphone..."
                                              prepend-inner-icon="mdi-phone"
                                              required
                                              type="number"
                                ></v-text-field>

                                <v-btn :disabled="!consumer_phone || consumer_phone.length !== 10"
                                       :loading="isLoading"
                                       color="primary"
                                       depressed
                                       :block="$vuetify.breakpoint.mobile"
                                       large
                                       type="submit">
                                    <v-icon left>mdi-send</v-icon>
                                    Envoyer
                                </v-btn>

                            </form>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            consumer_phone: null,
            success: false,
            errors: {},
            isLoading: false,
            overlay: false,
        }
    },
    methods: {
        send() {
            this.isLoading = true
            this.success = false
            this.error = false
            this.errors = {}

            axios.post(process.env.VUE_APP_BASE_URL + '/api/use-qrcode', {
                consumer_phone: this.consumer_phone,
                code: this.$route.params.code,
            }).then(() => {
                this.success = true
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                if (err.response.status === 422){
                    this.errors = err.response.data.errors
                }

                this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
                console.log(err)
            })
        },
        redirect() {
            this.overlay = true
            axios.post(process.env.VUE_APP_BASE_URL + '/api/redirect-qrcode', {
                code: this.$route.params.code,
            }).then(() => {
                this.overlay = false
            }).catch(err => {
                this.overlay = false
                console.log(err)
            })
        },
    },
    mounted() {
        this.redirect()
    }
}
</script>

<style scoped>

</style>